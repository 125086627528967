/** @jsx jsx */
import { jsx } from "theme-ui"
import { PageProps } from "gatsby"

import Layout from "components/layout"
import SEO from "components/seo"
import Calculator from "components/calculator"
import RelatedContent from "components/related-content"
import { getLocationState, LocationState } from "components/state-link"
import { Location as HLocation } from "history"

const Page = ({ location }: PageProps) => {
  const title = `Ипотека или аренда - калькулятор сравнения что выгоднее`
  const description = `Ипотечный калькулятор сравнивает что выгоднее: взять ипотечный кредит или копить (и арендовать)`
  const locationState = getLocationState((location as any) as HLocation<LocationState>)

  return (
    <Layout locationState={locationState}>
      <SEO title={title} description={description} pathname={location.pathname} />
      <h1 id="calculator">{title}</h1>
      <div>
        <hr />
      </div>
      <Calculator needCompareWithRent location={location} />
      <div id="content" sx={{ mt: 3 }}>
        <hr />
        <h2>Как мы сравниваем ипотеку с накоплением и арендой</h2>
        Мы рассматриваем следующую альтернативу ипотеке:
        <ol>
          <li>
            кладем первый взнос на ипотеку в банк под процент (процент задается в калькуляторе выше); если есть другие
            варианты инвестиций, то их тоже можно измерить годовой процентной ставкой прибыли;
          </li>
          <li>
            либо снимаем квартиру (тогда в форме выше надо задать стоимость ежемесячной арендной платы), либо живем в
            имеющейся квартире (тогда стоимость аренды надо задать как 0);
          </li>
          <li>
            каждый месяц разницу между ипотечным платежем (как будто бы мы платим ипотеку) и стоимостью аренды мы
            складываем в банковский депозит; если при этом стоимость аренды стала больше ипотечного платежа - накопление
            "проиграло": ипотека выгоднее! Также в эту разницу мы добавляем амортизированную стоимость ремонта за срок
            ипотеки и коммунальные платежи.
          </li>
          <li>
            каждые 12 месяцев мы увеличиваем стоимость квартиры и арендную плату за квартиру на заданные в калькуляторе
            выше проценты;
          </li>
          <li>для ипотеки добавляются дополнительные расходы на ремонт и ЖКХ; их можно обнулить по желанию;</li>
          <li>
            каждый месяц мы проверяем: если накопленная сумма сравнялась или превысила текущую стоимость квартиры, то
            накопление "выиграло": ипотека оказалась невыгодной!
          </li>
        </ol>
      </div>
      <RelatedContent locationState={locationState} />
    </Layout>
  )
}

export default Page
